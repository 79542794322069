import { BaseEntity, MemoizeGetters, Transform, toDate } from './base';
import moment, { MomentInput } from 'moment-timezone';
import { Day } from './day';

@MemoizeGetters
export class TimeSlot extends BaseEntity {
  startTime: string;
  available: boolean;

  @Transform(toDate)
  date: Date;

  get startTimeString() {
    return toTimeString(this.startTime, true);
  }

  get dateString() {
    return this.date.toDateString();
  }
}

export function toTimeString(value: MomentInput, inZone = false, timeZone?: string): string {
  const parser = inZone ? moment.parseZone : moment;
  const parsed = timeZone ? parser(value).tz(timeZone) : parser(value);
  const format = timeZone ? 'h:mma z' : 'h:mma';
  return parsed.format(format);
}

export function createDays(slots: TimeSlot[] = []) {
  slots.sort((slotA: TimeSlot, slotB: TimeSlot) => slotA.date.getTime() - slotB.date.getTime());
  const days: Day[] = [];
  for (let i = 0; i <= 6; i++) {
    const slotDate = slots.find((slot: TimeSlot) => slot.date.getDay() === i)?.date;
    let date = slotDate ?? nextDate(i);
    const dayFilter = (slot: TimeSlot) => slot.date.toDateString() === date.toDateString()
    const daySlots = slots.filter(dayFilter)
    const day = new Day(date, daySlots || []);
    days.push(day);
  }
  return sortDays(days);
}

function sortDays(days: Day[]) {
  return [...days].sort((a, b) => a.nextDate.getTime() - b.nextDate.getTime());
}

function nextDate(dayIndex: number) {
  const todayIndex = new Date().getDay();
  const diff = dayIndex >= todayIndex ? dayIndex - todayIndex : dayIndex - todayIndex + 7;
  return moment().add(diff, 'day').toDate();
}
