@if (isVisible) {
  <div class="dialog">
    <div class="dialog__content">
      <h3 class="dialog__content__heading">{{props.heading}}</h3>
      <p class="dialog__content__caption">{{props.caption}}</p>
      <div class="dialog__content__buttons">
        <ysh-button
          block
          [label]="props.buttonlLabelCancel || 'Cancel'"
          theme="smoke"
          (click)="handleCancel()"
        />
        <ysh-button
          block
          [label]="props.buttonLabelConfirm"
          [theme]="props.buttonThemeConfirm || 'blue'"
          (click)="handleConfirm()"
        />
      </div>
    </div>
  </div>
}
