import { ApiService, GetManyParams } from '../../api.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { CreditCard } from '../../../models/credit-card';


@Injectable({
  providedIn: 'root',
})
export class CreditCardService {
  constructor(private apiService: ApiService) {}


  create(token: string) {
    const url = `/users/${AuthService.USER_UID}/credit_cards`;
    return this.apiService.post(url, CreditCard, { token });
  }

  getMany(params: GetManyParams) {
    const url = `/users/${AuthService.USER_UID}/credit_cards`;
    return this.apiService.getMany(url, { ...params }, CreditCard);
  }
}
