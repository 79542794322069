import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { delay, firstValueFrom } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import { FormField, FormLink, YshFormComponent } from '../../components/ysh-form/ysh-form.component';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import generator from 'generate-password-ts'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
  standalone: true,
  imports: [YshFormComponent, YshSplitLayoutComponent],
})
export class SignupComponent implements OnInit {

  formFields: FormField[] = [
    { 
      name: 'firstName',
      label: 'First Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'lastName',
      label: 'Last Name',
      type: 'text',
      half: true,
      validators: [Validators.required] 
    },
    { 
      name: 'phone',
      label: 'Phone Number',
      type: 'tel',
      icon: 'phone',
      validators: [Validators.required]
    },
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      icon: 'alternate_email',
      validators: [Validators.required, Validators.email]
    }
  ];

  formLinks: FormLink[] = [
    {
      href: AppRoutes.Login,
      label: 'Have an Account? Sign in.',
    },
  ]

  constructor(private userService: UserService, private router: Router, private analytics: AnalyticsService) {}

  ngOnInit(): void {
    this.analytics.trackView('SignupComponent');
  }

  async handleSignup(formValues: any): Promise<void> {  
    const password = this.generatePassword();
    try {
      await firstValueFrom(
        this.userService.signup(
          formValues.firstName,
          formValues.lastName,
          formValues.phone,
          formValues.email,
          password
        ).pipe(delay(500))
      );
      await firstValueFrom(
        this.userService.login(formValues.email, password)
      );
      this.analytics.trackSignup();
      this.router.navigate([AppRoutes.OrderFlow]);
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    }
  }

  private generatePassword(){
    return generator.generate({
      length: 16,
      numbers: true,
      symbols: '%',
      uppercase: true,
      lowercase: true,
      strict: true
    });
  }
}
