import { Component, Input } from '@angular/core';

@Component({
  selector: 'ysh-dots',
  templateUrl: './ysh-dots.component.html',
  styleUrl: './ysh-dots.component.scss',
  standalone: true,
  imports: [],
})
export class YshDotsComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() color: 'blue' | 'space' | 'white' = 'space';

  get optionClasses(): string {
    return `size-${this.size} color-${this.color}`;
  }  
}
