import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SingleProp } from '../../../types/utility-types';

interface TileContentProps {
  text: string;
  imageSrc: string;
  videoSrc: string;
  iframeSrc: string;
}

export type TileContent = SingleProp<TileContentProps>;

@Component({
  selector: 'ysh-grid-tile-content',
  templateUrl: './ysh-grid-tile-content.component.html',
  styleUrl: './ysh-grid-tile-content.component.scss',
  standalone: true,
  imports: [],
})
export class YshGridTileContentComponent implements OnInit {
  @Input() text: TileContentProps['text'];
  @Input() imageSrc: TileContentProps['imageSrc'];
  @Input() videoSrc: TileContentProps['videoSrc'];
  @Input() iframeSrc: TileContentProps['iframeSrc'];
  @Input() dataFormat: any;

  iframeSrcSafe: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.iframeSrcSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
  }
}
