<div class="panel">
  @if (heading) {
    <div class="panel__headbar">
      <div class="panel__headbar__heading">{{heading}}</div>
      @if (actionLabel) {
        <div class="panel__headbar__action" (click)="handleAction()">{{actionLabel}}</div>
      }
      @else if (infotip) {
        <mat-icon class="panel__headbar__infotip" size-medium  [matTooltip]="infotip" matTooltipPosition="left">info</mat-icon>
      }
    </div>
  }
  <div class="panel__content" [class]="'padding-' + padding" data-empty-content="No Data...">
    @if (contentLoaded) {
      <ng-content></ng-content>
    }
    @else {
      <ysh-dots class="loading"/>
    }
  </div>
</div>
