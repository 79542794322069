import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { YshButtonComponent } from '../ysh-button/ysh-button.component';

export type YshBackButtonType = 'back' | 'close';

@Component({
  selector: 'ysh-back-button',
  templateUrl: './ysh-back-button.component.html',
  styleUrl: './ysh-back-button.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule, YshButtonComponent],
})
export class YshBackButtonComponent {

  @Input() type: YshBackButtonType = 'back';

  get icon(){
    if (this.type === 'back') {
      return 'arrow_back';
    } else if (this.type === 'close') {
      return 'close';
    }
  }

  get tooltip() {
    if (this.type === 'back') {
      return 'Go back';
    } else if (this.type === 'close') {
      return 'Exit';
    }
  }
}
