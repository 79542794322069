<ysh-split-layout theme="space" [hasLogo]="true">
  <ysh-form
    formHeading="Account Login"
    formSubmitLabel="Login"
    formErrorMessage="Invalid email or password. Please retry."
    [formLinks]="formLinks"
    [formFields]="formFields"
    [onSubmit]="handleLogin.bind(this)"
  ></ysh-form>
</ysh-split-layout>
