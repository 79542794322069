import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular/standalone';
import { ModalOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modalCtrl: ModalController) {}
  async present<T>(options: YoshiModalOptions<T>): Promise<HTMLIonModalElement> {
    const { backdropDismiss = true, animated = true } = options;
    if (options['props']) {
      options.componentProps = options.componentProps || {};
      options.componentProps['props'] = options['props'];
    }
    const modal = this.modalCtrl.create({
      ...options,
      backdropDismiss,
      showBackdrop: true,
      animated,
    });
    (await modal).present();
    return modal;
  }

  async dismiss(data?: any): Promise<boolean> {
    return this.modalCtrl.dismiss(data);
  }
}

type WithProps = { props: any };
export type ComponentType<T> = new (...args: any[]) => T;
export type PropType<T extends WithProps> = T['props'];

interface _YoshiModalOptions<T> extends ModalOptions {
  component: ComponentType<T>;
  /** @deprecated use props instead for type safety */
  componentProps?: Partial<T>;
  card?: boolean;
}

type YoshiModalOptions<T> = _YoshiModalOptions<T> &
  (T extends WithProps ? { props: T['props'] } : {});


