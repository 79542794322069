import { Component, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { YshPageHeaderComponent } from "../../components/ysh-page-header/ysh-page-header.component";
import { UserService } from '../../services/user/user.service';
import { YshPanelComponent } from '../../components/ysh-panel/ysh-panel.component';
import { Trait, YshTraitsComponent } from '../../components/ysh-traits/ysh-traits.component';
import { CreditCardService } from '../../services/api/credit-card/credit-card.service';
import { MatIconModule } from '@angular/material/icon';
import { ModalService } from '../../services/ui/modal/modal.service';
import { AddCardPage } from '../../flows/add-card/add-card.page';

@Component({
  selector: 'app-account',
  standalone: true,
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss',
  imports: [YshPageHeaderComponent, YshPanelComponent, YshTraitsComponent, MatIconModule],
})
export class AccountComponent implements OnInit {
  user: User;
  userInfo?: Trait[];
  userCreditCardInfo?: Trait[];

  constructor(
    private creditCardService: CreditCardService,
    private modalService: ModalService,
    private userService: UserService,
  ) {}
  
  ngOnInit() {
    this.getUserInfo();
    this.getUserCreditCard();
  }

  getUserInfo() {
    this.userService.currentUser$.subscribe(user => {
      this.user = user;
      this.userInfo = [
        { label: 'User Name', value: this.user?.name },
        { label: 'Email Address', value: this.user?.email },
        { label: 'Phone Number', value: this.user?.phone || 'Unknown' },
        { label: 'Account Created', value: this.user?.createdAtFormatted },
      ];
    });
  }

  getUserCreditCard() {
    this.creditCardService.getMany({}).subscribe(cards => {
      const card = cards.items[0];
      this.userCreditCardInfo = card && [
        { label: 'Card Number', value: '•••• •••• •••• ' + card?.last4 },
        { label: 'Card Type', value: card?.brand },
      ]
    })
  }

  // Actions

  handleAddCreditCard() {
    this.modalService.present(
      {
        component: AddCardPage,
        componentProps: {
          onComplete: () => {
            this.getUserCreditCard();
          } 
        } 
      }
    );
  }
}
