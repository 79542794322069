<div class="split-layout" [class.reverse]="reverseLayout">
  <div class="split-layout__visual" [class]="'theme-' + theme" [class.hide]="hideVisualForMobile">
    @if (hasLogo) {
      <img class="split-layout__visual__logo" [src]="srcForLogo">
    }
    <img class="split-layout__visual__image" [src]="srcForImage" [class.contain]="containImage" [class.reverse]="reverseImage">
  </div>
  <div class="split-layout__content">
    @if (hasBackButton) {
      <ysh-button
        class="split-layout__content__back"
        matTooltip="Go Back"
        matTooltipPosition="right"
        theme="smoke"
        icon="arrow_back"
        [isControl]="true"
        (click)="handleBack()"
      />
    }
    <ng-content></ng-content>
  </div>
</div>
