<aside class="aside" [ngClass]="{'active-mobile-menu': activeMobileMenu}">
  <nav class="aside__nav">
    @for (item of navItems; track item) {
      @if (item.subnav) {
        <a class="aside__nav__item" (click)="toggleSubnav($event)">
          <mat-icon size-large>{{item.icon}}</mat-icon> {{item.label}}
          <mat-icon size-large class="toggle-icon">
            {{visibleSubnav ? 'expand_less' : 'expand_more'}}
          </mat-icon>
        </a>
        @if (visibleSubnav) {
          @for (item of item.subnav; track item) {
            <div class="aside__subnav">
              <a class="aside__subnav__item" [class.icon-plus]="item.iconPlus" routerLink={{item.route}} routerLinkActive="active">
                {{item.label}}
              </a>
            </div>
          }
        }
      } @else {
        <a class="aside__nav__item" routerLink={{item.route}} routerLinkActive="active" [routerLinkActiveOptions]={exact:true}>
          <mat-icon size-large>{{item.icon}}</mat-icon> {{item.label}}
        </a>
      }
    }
  </nav>
</aside>
