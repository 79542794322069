<div class="order-page">
  @if (delivery) {
    <ysh-page-header
      title={{delivery.description}}
      backLinkRoute="/appointments"
      backLinkLabel="All Appointments"
    />
    <ysh-attributes
      [imageSrc]="''"
      [listItems]="deliveryAttributes"
    />
    @if (delivery.orders.length) {
      <h3>Services</h3>
      <br>
    }

    <ysh-table-set
      [dataSource]="orderDataSource"
    >
    </ysh-table-set>
  }

</div>
