
import { Injectable } from '@angular/core';
import { ApiService, GetManyParams } from '../../api.service';
import { Order, OrderStatus } from '../../../models/order';
import { AuthService } from '../../auth/auth.service';
import { MultipleOrderParams } from '../time-slot/time-slot.service';

// TODO: Update order api to be consistent with vehicle
export interface OrderCreateBulkParams {
  multipleOrderParams: MultipleOrderParams[],
  slotStartDateTime: string,
  date: Date,
  userUid: string
}


@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private apiService: ApiService) {}

  get(uid: string) {
    const url = `/orders/${uid}`;
    return this.apiService.get(url, Order);
  }

  createBulk(params: OrderCreateBulkParams) {
    const url = '/orders'
    return this.apiService.postBulk(url, Order, params)
;  }

  getMany(params: GetManyParams, status = OrderStatus.Pending) {
    const url = `/users/${AuthService.USER_UID}/orders`;
    return this.apiService.getMany(url, { ...params, status }, Order);
  }

  getManyForVehicle(uid, params: GetManyParams, status = OrderStatus.Complete) {
    const url = `/vehicles/${uid}/orders`;
    return this.apiService.getMany(url, { ...params, status }, Order);
  }

  cancel(uid: string) {
    const url = `/orders/${uid}/cancel`
    return this.apiService.post(url);
  }
}
