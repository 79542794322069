import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { FormField, YshFormComponent } from '../../components/ysh-form/ysh-form.component';
import { YshSplitLayoutComponent } from "../../components/ysh-split-layout/ysh-split-layout.component";
import { PasswordService } from '../../services/api/password/password.service';
import { ActivatedRoute, Router } from '@angular/router';
import { YshButtonComponent } from "../../components/ysh-button/ysh-button.component";
import { AppRoutes } from '../../app.routes';
import { checkMatchValidator } from '../../components/ysh-form/validators/match.validator';
import { checkPasswordValidator } from '../../components/ysh-form/validators/password.validator';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrl: './password-reset.component.scss',
  standalone: true,
  imports: [YshFormComponent, YshSplitLayoutComponent, YshButtonComponent],
})
export class PasswordResetPage implements OnInit {

  private readonly TOKEN_QUERY_PARAM = 'reset_password_token';

  token: string;

  emailFormFields: FormField[] = [
    {
      name: 'email',
      label: 'Email Address',
      type: 'email',
      icon: 'alternate_email',
      validators: [Validators.required, Validators.email]
    }
  ];

  passwordFormFields: FormField[] = [
    { 
      name: 'password',
      label: 'Password',
      type: 'password',
      icon: 'lock',
      validators: [Validators.required, checkPasswordValidator()]
    },
    { 
      name: 'confirmPassword',
      label: 'Confirm Password',
      type: 'password',
      icon: 'lock',
      validators: [Validators.required, checkMatchValidator('password')]
    }
  ]

  submitted = false;

  constructor(private router: Router, private route: ActivatedRoute, private password: PasswordService) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params[this.TOKEN_QUERY_PARAM];
    });
  }

  // Actions

  async onEmailSubmit(formValues: any) {
    try {
      await firstValueFrom(this.password.sendResetInstructions(formValues.email));
      this.submitted = true;
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    } 
  }

  async onPasswordSubmit(formValues) {
    try {
      await firstValueFrom(
        this.password.createNewPassword(formValues.password, formValues.passwordConfirmation, this.token)
      );
      this.submitted = true;
    } catch (error) {
      console.error(error);
      throw error; // Throw error for YshFormComponent to handle
    } 
  }

  didTapLogin() {
    this.router.navigate([AppRoutes.Login])
  }
}
