import { MemoizeGetters } from './base';
import { TimeSlot } from './time-slot';
import moment from 'moment';

const ALL_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const ALL_DAYS_ABBREVIATED = ['SU', 'M', 'TU', 'W', 'TH', 'F', 'SA'];
const DEFAULT_MINIMUM_WINDOW = 4;

@MemoizeGetters
export class Day {
  dayOfTheWeek: string;
  dayIndex = 0;
  displayName: string;
  slots: TimeSlot[] = [];
  date: Date;


  constructor(date: Date, slots: TimeSlot[] = []) {
    this.date = date;
    this.dayIndex = this.date.getDay();
    this.dayOfTheWeek = ALL_DAYS[this.dayIndex];
    this.displayName = ALL_DAYS_ABBREVIATED[this.dayIndex];
    this.slots = slots;
  }

  // Computed Properties

  get nextDate() {
    // If there are still slots available, use date
    if (this.slotsAvailable) {
      return this.date;
    }
    // Otherwise, calculate the next available date
    return this.nextDateForDayOfWeek(this.dayIndex);
  }

  private nextDateForDayOfWeek(dayIndex: number) {
    // If weekday has already passed this week, use next week
    const todayIndex = new Date().getDay();
    const diff = dayIndex > todayIndex ? dayIndex - todayIndex : dayIndex - todayIndex + 7;
    return moment().add(diff, 'day').toDate();
  }

  get availableSlots() {
    return this.slots.filter((slot) => slot.available);
  }

  get slotsAvailable() {
    return this.availableSlots.length > 0;
  }

  get dayOfWeek() {
    return moment(this.date).format('dddd');
  }

  get isToday() {
    const today = new Date();
    return today.toDateString() === this.nextDate.toDateString();
  }

  get isTomorrow() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toDateString() === this.nextDate.toDateString();
  }

  nextDateString(showStarts = true, showTomorrow = true) {
    const prefix = showStarts ? 'Starts ' : '';
    let dateString = '';
    if (this.isToday) {
      dateString = 'Today';
    } else if (showTomorrow && this.isTomorrow) {
      dateString = 'Tomorrow';
    } else {
      dateString = moment(this.nextDate).format('M/DD');
    }
    return `${prefix}${dateString}`;
  }
}
