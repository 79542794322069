import { Component, OnInit } from '@angular/core';
import { YshButtonTheme, YshButtonComponent } from '../ysh-button/ysh-button.component';
import { DialogService } from '../../services/ui/dialog/dialog.service';

export interface YshDialogProps {
  heading: string;
  caption: string;
  buttonLabelConfirm: string;
  buttonThemeConfirm?: YshButtonTheme;
  buttonlLabelCancel?: string;
  onConfirm: () => void;
}

@Component({
  selector: 'ysh-dialog',
  templateUrl: './ysh-dialog.component.html',
  styleUrl: './ysh-dialog.component.scss',
  standalone: true,
  imports: [YshButtonComponent],
})
export class YshDialogComponent implements OnInit {
  isVisible = false;
  props: YshDialogProps;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
    this.dialogService.dialogVisibility$.subscribe((isVisible) => {
      this.isVisible = isVisible;
      if (isVisible) {
        this.props = this.dialogService.getDialogProps();
      }
    });
  }

  handleConfirm() {
    this.props.onConfirm();
    this.dialogService.hideDialog();
  }

  handleCancel() {
    this.dialogService.hideDialog();
  }
}
