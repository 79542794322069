import { ApiService, GetManyParams } from '../../api.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { TimeSlot } from '../../../models/time-slot';

export interface MultipleOrderParams {
  serviceUid: string;
}

export interface TimeSlotGetManyParams extends GetManyParams {
  from: Date | null,
  to: Date | null,
  serviceUids?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class TimeSlotService {
  constructor(private apiService: ApiService) {}

  getMany(params: TimeSlotGetManyParams) {
    const url = `/users/${AuthService.USER_UID}/time_slots`;
    return this.apiService.getMany(url, { ...params, from: '2024-11-26', to: '2024-12-10' }, TimeSlot);
  }
}
