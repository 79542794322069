<div class="inspection-page">
  <ysh-page-header
    title="Inspection Details"
    backLinkRoute="/inspections"
    backLinkLabel="All Inspections"
  />
  <div class="grid">
    <ysh-panel
      class="grid__panel"
      heading="Overview"
      padding="left-right-bottom">

      @if (orderOverview) {
        <ysh-traits [traits]="orderOverview"/>
      }
    </ysh-panel>

    <ysh-panel
      class="grid__panel"
      heading="Results"
      infotip="Displayed as pass or fail"
      padding="left-right-bottom">

      @if (orderDetails) {
        <ysh-traits [traits]="orderDetails" heading="Inspection Point" labelOpacity="full"/>
      }
    </ysh-panel>

    <ysh-panel
      class="grid__panel"
      heading="Images"
      infotip="Tap image for details"
      padding="all-sides">

      @if (orderPhotos) {
        <ysh-gallery [images]="orderPhotos"/>
      }
    </ysh-panel>
  </div>
</div>
