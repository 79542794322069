import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'ysh-flow-nav-bar',
  templateUrl: './ysh-flow-nav-bar.component.html',
  styleUrl: './ysh-flow-nav-bar.component.scss',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
})
export class YshFlowNavBarComponent {

}
