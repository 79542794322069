"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCrypto = exports.getRootWebCrypto = exports.getWebCrypto = exports.getNodeCrypto = void 0;
/**
 * index.js
 **/
/**
 * Obtain require(crypto) in Node.js environment.
 * @return {undefined|Object} - Node.js crypto object
 */
var getNodeCrypto = function () {
    if (typeof window !== 'undefined' && window.crypto) {
        return undefined;
    }
    else if (typeof window === 'undefined' && typeof crypto !== 'undefined') {
        return undefined;
    }
    else
        return require('crypto');
};
exports.getNodeCrypto = getNodeCrypto;
/**
 * Obtain window.crypto.subtle object in browser environments.
 * @return {undefined|Object} - WebCrypto API object
 */
var getWebCrypto = function () {
    if (typeof window !== 'undefined' && window.crypto) { // standard window.crypto
        return window.crypto.subtle;
    }
    else if (typeof window === 'undefined' && typeof crypto !== 'undefined') { // case of service worker
        // eslint-disable-next-line no-undef
        return crypto.subtle;
    }
    return undefined;
};
exports.getWebCrypto = getWebCrypto;
/**
 * Obtain window.crypto object in browser environments.
 * @return {undefined|Object} - WebCrypto API object
 */
var getRootWebCrypto = function () {
    if (typeof window !== 'undefined' && window.crypto) {
        return window.crypto;
    }
    else if (typeof window === 'undefined' && typeof crypto !== 'undefined') {
        // eslint-disable-next-line no-undef
        return crypto;
    }
    return undefined;
};
exports.getRootWebCrypto = getRootWebCrypto;
/**
 * Get native crypto lib name.
 * @return {name: 'webCrypto'|'nodeCrypto'|undefined, crypto?: any}
 */
var getCrypto = function () {
    var webCrypto = getWebCrypto();
    var nodeCrypto = getNodeCrypto();
    if (typeof nodeCrypto !== 'undefined')
        return { name: 'nodeCrypto', crypto: nodeCrypto };
    else if (typeof webCrypto !== 'undefined')
        return { name: 'webCrypto', crypto: webCrypto };
    else
        return { name: undefined };
};
exports.getCrypto = getCrypto;
exports.default = { getNodeCrypto: getNodeCrypto, getWebCrypto: getWebCrypto, getRootWebCrypto: getRootWebCrypto, getCrypto: getCrypto };
