import { Component, Input } from '@angular/core';

export type YshBadgeActionTheme = 'blue' | 'coral' | 'shadow';

@Component({
  selector: 'ysh-badge-action',
  templateUrl: './ysh-badge-action.component.html',
  styleUrl: './ysh-badge-action.component.scss',
  standalone: true,
  imports: [],
})
export class YshBadgeActionComponent extends Component {
  @Input() theme: YshBadgeActionTheme = 'blue';
  @Input() value: string;
  @Input() disabled: boolean;
  @Input() onTap: () => void;

  handleTap() {
    this.onTap();
  }
}
