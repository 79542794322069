import { Component, Input, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular/standalone';
import { Service } from '../../../models/service';
import { YshFlowNavBarComponent } from '../../../components/ysh-flow-nav-bar/ysh-flow-nav-bar.component';
import { YshBackButtonComponent } from '../../../components/ysh-back-button/ysh-back-button.component';
import { YshFlowContentComponent } from "../../../components/ysh-flow-content/ysh-flow-content.component";
import { YshServiceCardComponent } from "../../../components/ysh-service-card/ysh-service-card";
import { FlowPage } from '../../flow-director';
import { AnalyticsService } from '../../../services/analytics/analytics.service';


interface ServiceSelectorPageProps {
  services: Service[];
}

@Component({
  standalone: true,
  selector: 'ysh-service-selector-page',
  templateUrl: 'service-selector.page.html',
  styleUrls: ['./service-selector.page.scss'],
  imports: [YshFlowNavBarComponent, YshBackButtonComponent, YshFlowContentComponent, YshServiceCardComponent]
})
export class ServiceSelectorPage implements FlowPage, OnInit {
  @Input() props: ServiceSelectorPageProps;
  @Input() onComplete: (service: Service) => void;
  @Input() onDismiss: () => void;

  selectedIndex: number;

  constructor(
    private analytics: AnalyticsService,
    private modalCtrl: ModalController) {}

  ngOnInit(): void {
    this.analytics.trackView('ServiceSelectorPage');
  }

  didTapDismiss() {
    if (this.onDismiss) {
      this.onDismiss();
    } else {
      this.modalCtrl.dismiss(false);
    }
  }
  didTapCard(index: number) {
    this.selectedIndex = index;
    this.onComplete(this.props.services[index]);
  }
}
