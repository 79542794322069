import { BaseEntity, MemoizeGetters } from "./base";

@MemoizeGetters
export class ServiceType extends BaseEntity {
  name: string;
  title: string;
  shortDescription: string;
}

export interface ServiceAttributes {
  title: string;
  shortDescription: string;
}