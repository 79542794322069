
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular/standalone';
import { Flow, FlowDirector, FlowPage, FlowPageOptions } from '../flow-director';
import { YshSplitLayoutComponent } from '../../components/ysh-split-layout/ysh-split-layout.component';
import { TimeSelectorPage } from './time-selector/time-selector.page';
import { AppointmentBuilderPage } from './appointment-builder/appointment-builder.page';
import { ServiceGroup } from '../../models/service-group';
import { Service } from '../../models/service';
import { TimeSlot } from '../../models/time-slot';
import { MultipleOrderParams } from '../../services/api/time-slot/time-slot.service';
import { ServiceService } from '../../services/api/service/service.service';
import { BackButtonService } from '../../shared/back-button.service';
import { OrderCreateBulkParams, OrderService } from '../../services/api/order/order.services';
import { User } from '../../models/user';
import { delay, from, map, mergeMap, Subject, takeUntil, timeout, timer } from 'rxjs';
import { Order } from '../../models/order';
import { OrderCompletePage } from './order-complete/order-complete.page';
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';
import { ServiceSelectorPage } from './service-selector/service-selector.page';
import { UserService } from '../../services/user/user.service';
import { ToastService } from '../../services/ui/toast/toast.service';
import { AddCardPage } from '../add-card/add-card.page';
import { CreditCardService } from '../../services/api/credit-card/credit-card.service';
import { ModalService } from '../../services/ui/modal/modal.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';

export interface OrderFlowProps {
  serviceGroup: ServiceGroup;
}

interface OrderFlowForm {
  multipleOrderParams?: Nullable<MultipleOrderParams[]>;
  duration?: string;
  availableSlots?: TimeSlot[];
  serviceGroup?: ServiceGroup;
  timeSlot?: TimeSlot;
  user?: User;
  orders?: Order[];
  services?: Service[];
  hasCard?: boolean;
  didCompleteAppointmentBuilder?: boolean;
  didComplete?: boolean;
}

@Component({
  standalone: true,
  selector: 'ysh-ordering-flow',
  template: `
    <ysh-split-layout 
      [theme]="isOrderCompletePage ? 'blue' : 'smoke'"
      [hideVisualForMobile]="true"
      [reverseImage]="!isOrderCompletePage"
      [containImage]="isOrderCompletePage"
      [srcImage]="isOrderCompletePage && '/assets/images/graphic-order-complete.svg'"
    >
      <ion-nav #webflowNav></ion-nav>
    </ysh-split-layout>`,
  imports: [
    YshSplitLayoutComponent, IonNav
  ]
})
export class OrderingFlow implements Flow, OnInit, AfterViewInit, OnDestroy {
  @ViewChild('webflowNav') nav: IonNav;
  @Input() props: OrderFlowProps;
  @Input() onDismiss: () => void;

  form: OrderFlowForm = {}

  // Flow
  flowDirector: FlowDirector;
  preventAnimation?: boolean = true;
  isOrderCompletePage: boolean = false;

  count = 1;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private analytics: AnalyticsService,
    private creditCardService: CreditCardService,
    private modalService: ModalService,
    private router: Router,
    private serviceService: ServiceService, 
    private toastService: ToastService,
    private userService: UserService,
    public backButtonService: BackButtonService, 
    public orderService: OrderService) {}

  ngOnInit() {
    console.log("Init")
    this.userService.currentUser$.pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.form.user = user;
    });
    this.creditCardService.getMany({}).subscribe(cards => {
      this.form.hasCard = Boolean(cards.items.length);
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    console.log("After Init")

    this.serviceService.getMany({}).subscribe((response)=>{
      const search = 'DOT';
      const services = response.items.filter(item => item.matchesSearch([search]));
      this.form.serviceGroup = ServiceGroup.groupServices(services)[0];
      this.flowDirector = new FlowDirector(this);
    });
 
  }

  // flow

  nextPage(): Nullable<FlowPageOptions<FlowPage>> {
    if (!this.form.multipleOrderParams?.length) {
      return this.optionForServiceSelectorPage();
    }
    if (!this.form.didCompleteAppointmentBuilder){
      return this.optionsForAppointmentBuilder();
    }
    if (!this.form.timeSlot) {
      return this.optionsForTimeSelectorPage();
    }
    if (!this.form.didComplete){
      return this.optionsForOrderCompletePage();
    }
  }

  flowDidComplete(): void {
    this.router.navigate([AppRoutes.Appointments]);
  }

  // Page Params

  // ServiceSelectorPage

  private optionForServiceSelectorPage(): FlowPageOptions<ServiceSelectorPage> {
    return {
      page: ServiceSelectorPage,
      urlHash: 'select-service',
      onComplete: (service: Service) => {
        this.didSelectMultipleServices([service]);
      },
      onDismiss: () => this.router.navigate([AppRoutes.Appointments]),
      props: {
        services: this.form.serviceGroup.services
      },
    };
  }

  // AppointmentBuilderPage

  private optionsForAppointmentBuilder(): FlowPageOptions<AppointmentBuilderPage> {
    return {
      page: AppointmentBuilderPage,
      urlHash: 'build-appointment',
      onComplete: (services, slots) => {
        this.form.didCompleteAppointmentBuilder = true;
        this.didSelectMultipleServices(services, slots);
      },
      onDismiss: () => (this.form.didCompleteAppointmentBuilder = false),
      props: {
        services: this.form.services,
        serviceGroup: this.form.serviceGroup,
      },
    };
  }

  private didSelectMultipleServices(services: Service[], slots?: TimeSlot[]) {
    this.form.multipleOrderParams = services.map((item) => {
      return { serviceUid: item.uid };
    });
    const totalDuration: number = services.reduce((sum, service) => sum + service.stopDuration, 0);
    this.form.duration = Service.durationString(totalDuration);
    this.form.availableSlots = slots;
    this.form.services = services;
    this.flowDirector.next();
  }


  // TimeSelectorPage

  private optionsForTimeSelectorPage(): FlowPageOptions<TimeSelectorPage> {
    return {
      page: TimeSelectorPage,
      urlHash: 'select-time',
      onComplete: (slot: TimeSlot) => {
        this.form.timeSlot = slot;
        const prompt = this.promptForPayment();
        return from(prompt).pipe(
          mergeMap(()=>timer(1000)),
          mergeMap(()=>this.saveOrder()),
          map(Boolean)
        )
      },
      onDismiss: () => {
        this.form.timeSlot = null;
        this.onDismiss?.();
      },
      props: {
        serviceUids: this.form.multipleOrderParams.map(item => item.serviceUid),
        timeSlots: this.form.availableSlots,
        duration: this.form.duration
      },
    };
  }

  // OrderCompletePage

  private optionsForOrderCompletePage(): FlowPageOptions<OrderCompletePage> {
    this.isOrderCompletePage = true;
    return {
      page: OrderCompletePage,
      urlHash: 'order-complete',
      onComplete: () => {
        this.form.didComplete = true;
        this.flowDirector.next();
      },
      preventBackNavigation: true,
      props: {
        orders: this.form.orders,
      },
    };
  }

  // Pre-Order Modal Flow 
  
  private promptForPayment(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      if (this.form.hasCard) {
        return resolve(true);
      }
      const paymentSheet = await this.modalService.present(
        {
          component: AddCardPage,
          componentProps: {
            onComplete: (result) => {
              this.form.hasCard = true;
              result ? resolve(true) : reject();
            },
            onDismiss: () => {
              this.modalService.dismiss();
              reject();
            }
          } 
        }
      );
      const data = await paymentSheet.onDidDismiss();
      resolve(data);
    });
  }

  // Data

  saveOrder() {
    const params: OrderCreateBulkParams = {
      slotStartDateTime: this.form.timeSlot.startTime,
      date: this.form.timeSlot.date,
      multipleOrderParams: this.form.multipleOrderParams,
      userUid: this.form.user.uid
    };
    const req = this.orderService.createBulk(params)
    req.subscribe({
      next: (orders) => {
        this.form.orders = orders;
        this.analytics.trackPurchase(orders);
        this.flowDirector.next();
      },
      error: (error) => {
        const message = error.error?.errors?.[0].message;
        const errorMessage = `Error creating order: ${message}`;
        this.toastService.show('error', errorMessage);
      }
    })
    return req;
  }
}
