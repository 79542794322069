import { Injectable } from "@angular/core";
import { ApiService } from "../../api.service";
import { AppRoutes } from "../../../app.routes";
import { Observable } from "rxjs";

export interface PasswordResetResponse {
  email: string; 
  messages: string;
}

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  constructor(private apiService: ApiService) {}

  sendResetInstructions(email: string): Observable<PasswordResetResponse> {
    const redirectUrl = `${location.origin}/${AppRoutes.Password}`;
    const url = `/auth/password`;
    return this.apiService.call({
      url,
      method: 'POST',
      body: {
        email,
        redirectUrl,
      }
    });
  }

  createNewPassword(password: string, passwordConfirmation: string, resetPasswordToken: string) {
    const url = `/auth/password`;
    return this.apiService.call({
      url,
      method: 'PUT',
      body: {
        password,
        passwordConfirmation,
        resetPasswordToken
      }
    });
  }
}
