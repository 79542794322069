<form class="form" [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
  <h2 class="form__heading">{{ formHeading }}</h2>
  @if (formCaption) { 
    <p class="form__caption">{{ formCaption }}</p>
  }
  @if (formErrorMessages.length) {
    <p class="form__errors">
      @for (message of formErrorMessages; track $index) {
        <span>{{ message }}</span>
      }
    </p>
  }
  @for (formField of formFields; track formField) {
    <mat-form-field class="form__field" [class.half]="formField.half">
      <mat-label>{{ formField.label }}</mat-label>

      <input matInput [formControlName]="formField.name" [type]="formField.type">
      @if(formField.icon) {
        <mat-icon size-medium matSuffix>{{ formField.icon }}</mat-icon>
      }
      @if(fieldErrorMessages[formField.name]){
        <mat-error>{{fieldErrorMessages[formField.name]}}</mat-error>
      }
    </mat-form-field>
  }
  <ysh-button
    class="form__submit"
    [label]="formSubmitLabel"
    [loading]="formSubmitting"
    [disabled]="formSubmitting || !formGroup.valid"
  />
  @if (formLinks) {
    @for (formLink of formLinks; track formLink) {
      <a class="form__link" href={{formLink.href}}>{{ formLink.label }}</a>
    }
  }
</form>
