import { Injectable } from '@angular/core';
import { Order } from '../../models/order';

declare var gtag: any;

enum AnalyticsEvents {
  PageView = 'page_view',
  Purchase = 'purchase',
  SignUp = 'sign_up'
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor() {}

  trackView(title: string) {
    this.trackGooglePageView(title);
  }

  trackSignup(){
    gtag('event', AnalyticsEvents.SignUp);
  }

  trackPurchase(orders: Order[]){
    gtag("event", AnalyticsEvents.Purchase, {
      value: this.ordersToValue(orders),
      currency: "USD",
      items: this.ordersToGoogleItems(orders),
    });
  }

  private ordersToValue(orders: Order[]){
    return orders.reduce(
      (sum, order) => sum + order.service.price,
      0
    )
  }

  private ordersToGoogleItems(orders: Order[]){
    return orders.map(order => ({
      item_name: order.service.title,
      item_id: order.service.uid,
      price: order.service.price,
      quantity: 1
    }))
  }

  private trackGooglePageView(page_title: string) {
    gtag('event', AnalyticsEvents.PageView, {
      page_title,
    });
  }
}

