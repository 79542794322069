<div class="schedule-cells" [class]="'columns-' + columns">
  @for (cell of cells; track $index) {
    <button class="schedule-cell" [class.selected]="cell.selected" [disabled]="cell.disabled" (click)="cell.onSelect()">
      <span class="schedule-cell__value">{{cell.value}}</span>
      @if (cell.subvalue) {
        <span class="schedule-cell__subvalue">{{cell.subvalue}}</span>
      }
    </button>
  }
</div>
