import { ServiceAttributes, ServiceType } from './service-type';

import { MemoizeGetters } from './base';
import { Service } from './service';
import _ from 'underscore';

/**
 * A grouping of ServiceModels that share a common ServiceType
 */

@MemoizeGetters
export class ServiceGroup {
  readonly services: Service[];
  readonly serviceType: ServiceType;
  readonly basicService: Service;
  readonly name: string = '';
  readonly price: number = 0;

  constructor(services: Service[]) {
    this.services = services || [];
    this.services.sort((a, b) => a.price - b.price);
    this.basicService = services[0];
    this.serviceType = this.basicService && this.basicService.serviceType;
    this.name = this.basicService.name;
    this.price = this.basicService.price;
  }
  /**
   * @param  {Service[]} services a list of ungrouped services
   * @returns ServiceGroup[] services organized as a list of ServiceGroups
   */
  static groupServices(services: Service[]): ServiceGroup[] {
    let serviceMap = _.groupBy(services, (service: Service) => {
      return service.serviceType.name;
    });
    let serviceGroup: ServiceGroup[] = [];
    _.each(serviceMap, (services: Service[]) => {
      let group = new ServiceGroup(services);
      serviceGroup.push(group);
    });

    // Show featured services first, then sort by priority
    return serviceGroup.sort((a, b) => {
      return a.basicService.priority - b.basicService.priority;
    });
  }

  get title() {
    return this.attributeSource.title;
  }

  get shortDescription() {
    return this.attributeSource.shortDescription;
  }

  private get attributeSource(): ServiceAttributes {
    return this.services.length === 1 ? this.basicService : this.serviceType;
  }
}
