import moment from "moment";
import { BaseEntity, MemoizeGetters, toDate, toEntity, Transform } from "./base";
import { Order } from "./order";

@MemoizeGetters
export class Delivery extends BaseEntity{
  @Transform(toEntity(Order))
  orders: Order[]

  @Transform(toDate)
  date: Date; 

  status: string 
  slotStartTime: string; 
  slotEndTime: string;

  get dateTimeString() {
    return `${this.dateString} - ${this.timeWindowString}`;
  }

  get serviceCount() {
    return this.orders.reduce((obj, order) => {
      const key = order.serviceType.title;
      obj[key] = obj[key] ? obj[key]+1 : 1;
      return obj;
    }, {});
  }

  get serviceDescriptionString() {
    let description = '';
    return Object.keys(this.serviceCount).map(name => {
      const count = this.serviceCount[name];
      const countString = count > 1 ? ` (${count})` : ``;
      description = description + `${name}${countString} `
      return description.trim();
    }).join(', ');
  }

  get dateString() {
    return moment(this.date).format('MMM Do YYYY');
  }

  get timeWindowString() {
    return `${this.slotStartTime} - ${this.slotEndTime}`;
  }

  get vehicleCount() {
    return this.orders.length;
  }

  get description() {
    // TODO: Create description
    return "Appointment"
  }

  get costString() {
    const cost = this.orders.reduce((cost, order) => cost + order.service.price, 0);
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(cost); 
  }

}