import { Stripe, loadStripe } from '@stripe/stripe-js';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  instance: Stripe | null;

  constructor() {
    this.initializeStripe();
  }

  async initializeStripe(): Promise<Stripe | null> {
    const stripeKey = environment.stripePublishableKey;
    this.instance = await loadStripe(stripeKey);
    return this.instance;
  }
}
