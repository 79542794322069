import { BaseEntity, Default, MemoizeGetters, Transform } from './base';
import { toNumber } from './base/transform';

enum ChargeType {
  QuickBooks = 'quickbooks_charging',
  Stripe = 'stripe_charging',
  Manual = 'manual_charging',
}

enum ChargeStatus {
  NotStarted = 'Not started',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  WaitingToRetry = 'Waiting to retry',
}

enum PaymentStatus {
  Open = 'open',
  PartiallyPaid = 'partially_paid',
  Paid = 'paid'
}

@MemoizeGetters
export class Invoice extends BaseEntity {

  @Transform(toNumber)
  amount: number;
  chargeStatus: ChargeStatus;

  @Default(PaymentStatus.Open)
  sagePaymentStatus: PaymentStatus;

  @Default("https://www.google.com")
  sagePaymentLink?: string;

  chargeType: ChargeType;
  startDate: string;
  endDate: string;
  pdfDownloadLink: string;
  csvDownloadLink: string;
  invoiceNumber: string;

  get amountString(){
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(this.amount); 
  }

  get statusString() {
    if (this.amount === 0){
      return "Paid"
    }
    if (this.chargeStatus === ChargeStatus.Succeeded){
      return "Paid"
    }
    if (this.chargeStatus === ChargeStatus.Failed){
      return "Failed"
    }
    return "Pending"
  }
}

