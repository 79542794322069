"use strict";
/**
 index.ts
 **/
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateMultiple = exports.generate = void 0;
var generate_1 = require("./generate");
Object.defineProperty(exports, "generate", { enumerable: true, get: function () { return generate_1.generate; } });
Object.defineProperty(exports, "generateMultiple", { enumerable: true, get: function () { return generate_1.generateMultiple; } });
exports.default = { generate: generate_1.generate, generateMultiple: generate_1.generateMultiple };
