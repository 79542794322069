<div class="account-page">
  <ysh-page-header title="Account Profile"/>
  <div class="panels">
    <ysh-panel
      class="panel"
      heading="Information"
      padding="left-right-bottom">

      <div class="panel__content-info">
        <img src="assets/images/default-account.png">
        <ysh-traits [traits]="userInfo"/>
      </div>
    </ysh-panel>

    <ysh-panel
      class="panel"
      heading="Payment"
      [actionLabel]="userCreditCardInfo ? 'Change' : 'Add Card'"
      (onAction)="handleAddCreditCard()"
      padding="left-right-bottom">

      @if(userCreditCardInfo) {
        <ysh-traits [traits]="userCreditCardInfo"/>
      }
    </ysh-panel>
  </div>
</div>
