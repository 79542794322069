import { ApiService, GetManyParams } from '../../api.service';
import { Injectable } from '@angular/core';
import { Vehicle } from '../../../models/vehicle';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleService {
  constructor(private apiService: ApiService) {}

  getMany(params: GetManyParams) {
    const url = `/users/${AuthService.USER_UID}/vehicles`;
    return this.apiService.getMany(url, params, Vehicle);
  }

  get(uid: string) {
    const url = `/vehicles/${uid}`;
    return this.apiService.get(url, Vehicle);
  }
}
