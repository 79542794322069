import pluralize from 'pluralize';

import { BaseEntity, Transform } from "./base";
import { ServiceType } from "./service-type";

export class Service extends BaseEntity{
  name: string;
  //title: string;

  @Transform(parseFloat)
  price: number;
  description: string;
  enabled: boolean;
  priority: number;
  shortDescription: string;
  stopDuration: number;

  static durationString(durationInMinutes: number) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    const hourString = hours ? `${hours} ${pluralize('hour', hours)}` : '';
    const minuteString = minutes ? `${minutes} ${pluralize('minute', hours)}` : '';
    return `${hourString} ${minuteString}`.trim();
  }

  matchesSearch(keywords: string[]): boolean {
    const fullTitle = `${this.name} ${this.title}`.toLowerCase();
    return keywords.every((word) => fullTitle.includes(word.toLowerCase()));
  }

  get durationString() {
    return Service.durationString(this.stopDuration);
  }

  get serviceType(): ServiceType {
    return new ServiceType({name: "inspection"})
  }

  get title() {
    return this.name;
  }
}
