import { Component, OnInit } from '@angular/core';
import { TableDataSouce, TableFilter, YshTableSetComponent } from "../../components/ysh-table-set/ysh-table-set.component";
import { Router } from '@angular/router';
import { AppRoutes } from '../../app.routes';
import { Order, OrderStatus } from '../../models/order';
import { OrderService } from '../../services/api/order/order.services';
import { YshBadgeComponent } from '../../components/ysh-badge/ysh-badge.component';

@Component({
  selector: 'app-inspections',
  templateUrl: './inspections.component.html',
  styleUrl: './inspections.component.scss',
  standalone: true,
  imports: [YshTableSetComponent],
})
export class InspectionsComponent implements OnInit {

  dataSource: TableDataSouce<Order>;

  constructor(private orderService: OrderService, private router: Router) {}

  ngOnInit(): void {
    this.dataSource = {
      title: 'Inspection History',
      // TODO: filter and sorting fails with 'getter' field values
      columns: [
        { title: 'Description', field: 'serviceNameString', sortable: true, cellSize: 'large' },
        { title: 'Vehicle', field: 'vehicleDescriptionString', sortable: true },
        { title: 'VIN', field: 'vehicleVinString', sortable: true },
        { title: 'Completed', field: 'timeCompletedString', sortable: true },
        { title: 'Result', field: 'inspectionResultString', sortable: true, component: YshBadgeComponent }
      ],
      filters:[TableFilter.Search],
      actions: [],
      displayRowNav: true,
      load: (state, status = OrderStatus.Complete) => this.orderService.getMany(state, status),
    }
  }
  
  didTapItem(order: Order) {
    this.router.navigate([AppRoutes.Inspections, order.uid]);
  }
}
