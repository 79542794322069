import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterLinkActive, RouterModule, RouterOutlet } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Report } from './../../models/report';
import { CommonModule } from '@angular/common';
import { ReportService } from '../../services/report.service';
import { AppRoutes } from '../../app.routes';

export interface NavItem {
  label: string,
  icon?: string,
  route?: AppRoutes | string,
  subnav?: {
    label: string,
    iconPlus?: boolean;
    route: AppRoutes | string,
  }[],
}

@Component({
  selector: 'ysh-main-aside',
  templateUrl: './ysh-main-aside.component.html',
  styleUrl: './ysh-main-aside.component.scss',
  standalone: true,
  imports: [CommonModule, RouterModule, RouterLinkActive, MatIconModule],
})
export class YshMainAsideComponent implements OnInit {
  @Input() activeMobileMenu: boolean;

  reports: Report[] = [];
  visibleSubnav = false;

  navItems: NavItem[] = [
    {
      route: AppRoutes.Dashboard,
      label: 'Dashboard',
      icon: 'dashboard',
    },
    {
      label: 'Inspections',
      icon: 'task',
      subnav: [
        {
          route: AppRoutes.Appointments,
          label: 'Appointments',
        },
        {
          route: AppRoutes.Inspections,
          label: 'Inspection History',
        },
        {
          route: AppRoutes.OrderFlow,
          label: 'Schedule Inspection',
          iconPlus: true,
        },
      ],
    },
    {
      route: AppRoutes.Vehicles,
      label: 'Vehicles',
      icon: 'local_shipping',
    },
    {
      route: AppRoutes.Invoices,
      label: 'Invoices',
      icon: 'request_quote',
    },
    {
      route: AppRoutes.Account,
      label: 'Account',
      icon: 'account_circle',
    },
  ];

  constructor(private reportService: ReportService, private router: Router){}

  ngOnInit(): void {
    this.reportService.getMany().subscribe(({items}) => {
      this.reports = items;
      this.addReportsToNav(items);
    });
  }

  addReportsToNav(reports: Report[]) {
    if (reports?.length) {
      const reportsNav: NavItem = {
        label: 'Reports',
        icon: 'description',
        subnav: []
      }

      reports.forEach(report => {
        const route = `reports/${report.name}`
        reportsNav.subnav.push({
          label: report.title,
          route
        })
        this.visibleSubnav = this.visibleSubnav || this.router.isActive(route, true);
      })
      this.navItems = [...this.navItems, reportsNav];
    }
  }

  toggleSubnav(event) {
    this.visibleSubnav = !this.visibleSubnav;
    event.stopPropagation();
  }
}
