import { Component, Input, OnInit } from "@angular/core";
import { Order } from "../../../models/order";
import { FlowPage } from "../../flow-director";
import { YshFlowNavBarComponent } from "../../../components/ysh-flow-nav-bar/ysh-flow-nav-bar.component";
import { YshFlowContentComponent } from "../../../components/ysh-flow-content/ysh-flow-content.component";
import { YshFlowFooterComponent } from "../../../components/ysh-flow-footer/ysh-flow-footer.component";
import { DatePipe } from "@angular/common";
import { YshButtonComponent } from "../../../components/ysh-button/ysh-button.component";
import { OrderService } from "../../../services/api/order/order.services";
import { DialogService } from "../../../services/ui/dialog/dialog.service";
import { YshServiceCardComponent } from "../../../components/ysh-service-card/ysh-service-card";
import { AnalyticsService } from "../../../services/analytics/analytics.service";

export interface OrderCompletePageProps {
  orders: Order[];
}

@Component({
  standalone: true,
  selector: 'ysh-order-complete-page',
  templateUrl: './order-complete.page.html',
  styleUrls: ['./order-complete.page.scss'],
  imports: [YshFlowNavBarComponent, YshFlowContentComponent, YshFlowFooterComponent, DatePipe, YshButtonComponent, YshServiceCardComponent],
})
export class OrderCompletePage implements FlowPage, OnInit {
  @Input() props: OrderCompletePageProps;

  onComplete: () => void;
  onDismiss: () => void;

  constructor(
    private analytics: AnalyticsService,
    private dialogService: DialogService, 
    private orderService: OrderService) {}

  ngOnInit(): void {
    this.analytics.trackView('OrderCompletePage');
  }

  get introContent() {
    if (this.props.orders.length) {
      return {
        heading: 'Appointment Scheduled!',
        message: 'Thanks for scheduling an appointment. We’ll send you an SMS confirmation shortly with additional instructions.',
      };
    } else {
      return {
        heading: 'No Appointments!',
        message: 'There are no appointments scheduled. Please proceed to the dashboard, where you can schedule at a later time.',
      };
    }
  }

  // actions

  async didTapCancel(order: Order) {
    this.dialogService.showDialog({
      heading: 'Confirm Removal!',
      caption: 'Are you sure you want to remove this item? The action is final and cannot be undone.',
      buttonlLabelCancel: 'Not Now',
      buttonLabelConfirm: 'Yes, Remove',
      buttonThemeConfirm: 'coral',
      onConfirm: () => this.cancelOrder(order),
    });
  }

  didTapDone() {
    this.onComplete();
  }
  // data

  private cancelOrder(order: Order) {
    this.orderService.cancel(order.uid).subscribe(()=>{
      const index = this.props.orders.indexOf(order);
      this.props.orders = this.props.orders.filter(item => item.uid !== order.uid);
    });
  }
}
