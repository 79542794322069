import { Component, OnDestroy } from '@angular/core';
import { RouterModule, RouterOutlet, RouterLinkActive, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { User } from './models/user';
import { UserService } from './services/user/user.service';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { YshMainAsideComponent } from "./components/ysh-main-aside/ysh-main-aside.component";
import { YshMainHeaderComponent } from "./components/ysh-main-header/ysh-main-header.component";
import { YshToastComponent } from "./components/ysh-toast/ysh-toast.component";
import { ToastService } from './services/ui/toast/toast.service';
import { YshDialogComponent } from './components/ysh-dialog/ysh-dialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    RouterModule,
    RouterOutlet,
    MatIconModule,
    CommonModule,
    YshMainAsideComponent,
    YshMainHeaderComponent,
    YshToastComponent,
    YshDialogComponent,
  ],
})
export class AppComponent implements OnDestroy {
  title = 'fleet-portal';
  activeMobileMenu = false;
  isLoggedIn = false;
  currentUser: User;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private userService: UserService, private toastService: ToastService, public router: Router) {
    this.userService.loggedIn$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isLoggedIn => (this.isLoggedIn = isLoggedIn));
    this.userService.currentUser$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(user => (this.currentUser = user));
  }
  
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  didTapLogout() {
    this.userService.logout();
    this.toastService.show('success', 'Successful Logout');
  }

  didTapMobileMenu() {
    this.activeMobileMenu = !this.activeMobileMenu;
  }
}
