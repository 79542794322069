<div class="gallery" [class]="'gap-' + gridGap">
  @for (image of images; track image) {
    <div class="gallery__image">
      <div class="gallery__image__overlay">
        <mat-icon size-large>zoom_in</mat-icon>
      </div>
      <img src={{image.src}} (click)="toggleModal($index)">
    </div>
  }
</div>

<ysh-modal [isVisible]="modalVisible" (onClose)="toggleModal()">
  <ysh-carousel [items]="images" [activeItem]="carouselActiveItem"/>
</ysh-modal>
