<ysh-split-layout theme="blue" [hasLogo]="true">
  <div>
    <ysh-form
    formHeading="Appointments are available! Add your info to see scheduling options!"
    formSubmitLabel="Continue"
    [formLinks]="formLinks"
    [formFields]="formFields"
    [onSubmit]="handleSignup.bind(this)"
  ></ysh-form>
  <div class="legal-text">
    By continuing you agree to Yoshi’s
    <a href='https://www.yoshimobility.com/terms'>Terms of Service</a> and
    <a href='https://www.yoshimobility.com/privacy'>Privacy Policy</a>
    You also agree to receive recurring text messages from Yoshi for the purposes of customer
    support, delivery notifications and order reminders. Msg and data rates may apply. Reply HELP
    for help and STOP to unsubscribe.
  </div>
  </div>
</ysh-split-layout>
