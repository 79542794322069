import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function checkMatchValidator(field: string): ValidatorFn {
  return function(control: AbstractControl): ValidationErrors | null {
    let value1 = control.value;
    let value2 = control?.parent?.get(field)?.value;
    if (value1 !== '' && value1 !== value2) {
      return { 'match': `Does not match ${field}` }
    }
    return null;
  }
}