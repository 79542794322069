import { BaseEntity, MemoizeGetters } from "./base";
import { Photo } from "./photos";

interface Inspector {
  firstName: string;
  lastName: string;
}

interface InspectionResultDetails {
  inspection_result: string;
  inspection_date: string;
  inspection_type: string;
  inspection_details: {
    question: string;
    answer: string;
  }[];
}

interface LatestInspectionDocument {
  inspectionPdfUrl: string;
}

@MemoizeGetters
export class Inspection extends BaseEntity {
  inspector: Inspector;
  inspectionPhotos: Photo[];
  inspectionResultDetails: InspectionResultDetails;
  latestInspectionDocument: LatestInspectionDocument;

  private get resultDetails(): InspectionResultDetails {
    return JSON.parse(this.inspectionResultDetails[0]);
  }

  get inspectorName() {
    return this.inspector.firstName + ' ' + this.inspector.lastName;
  }

  get result() {
    return this.resultDetails.inspection_result;
  }

  get date() {
    return this.resultDetails.inspection_date;
  }

  get type() {
    return this.resultDetails.inspection_type;
  }

  get details() {
    return this.resultDetails.inspection_details.map(detail => ({
      // Replace underscores with spaces and capitalize first word
      question: detail.question.replace(/_/g, ' ').replace(/^./, char => char.toUpperCase()),
      answer: detail.answer
    }));
  }
  
  get documentUrl() {
    return this.latestInspectionDocument.inspectionPdfUrl;
  }

  get photos() {
    return this.inspectionPhotos;
  }
}
