import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { YshDotsComponent } from "../ysh-dots/ysh-dots.component";

export type YshButtonTheme = 'blue' | 'coral' | 'shadow' | 'smoke' | 'white' | 'clear';

@Component({
  selector: 'ysh-button',
  templateUrl: './ysh-button.component.html',
  styleUrl: './ysh-button.component.scss',
  standalone: true,
  imports: [
    MatIconModule,
    MatProgressSpinnerModule,
    YshDotsComponent,
  ],
})
export class YshButtonComponent {
  @Input() theme: YshButtonTheme = 'blue';
  @Input() label?: string;
  /** Name from Material Icons */
  @Input() icon?: string;
  /** Show animated loading spinner */
  @Input() loading?: boolean;
  /** Style for control buttons: back, close, etc */
  @Input() isControl?: boolean;
  @Input() disabled?: boolean;
}
