import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ysh-service-card',
  templateUrl: 'ysh-service-card.html',
  styleUrl: './ysh-service-card.scss',
  standalone: true,
  imports: [MatIconModule],
})
export class YshServiceCardComponent {
  @Input() title: string | number;
  @Input() details: string | number;
  /** Name from Material Icons */
  @Input() detailsIcon?: string;
  @Input() description?: string;
  @Input() selected?: boolean;
  @Input() selectable?: boolean;
  @Input() deletable?: boolean;
  @Output() onDelete = new EventEmitter();

  handleDelete() {
    this.onDelete.emit();
  }
}
