import { Component, Input } from '@angular/core';

export interface YshScheduleCell {
  value: string | number,
  subvalue?: string | number,
  disabled: boolean;
  selected: boolean;
  onSelect: () => void;
}

@Component({
  selector: 'ysh-schedule-cells',
  templateUrl: './ysh-schedule-cells.component.html',
  styleUrl: './ysh-schedule-cells.component.scss',
  standalone: true,
  imports: [],
})
export class YshScheduleCellsComponent {
  @Input() cells: YshScheduleCell[] = [];
  @Input() columns: '3' | '4' | '5' | 'auto' = 'auto';
}
