<ysh-flow-nav-bar><ysh-back-button (click)="didTapBack()"></ysh-back-button></ysh-flow-nav-bar>
<ysh-flow-content>
  <div class="intro">
    <h2 class="intro__heading">Next, select a day and time to schedule your service!</h2>
  </div>
  <div class="body">
    <ysh-schedule-cells class="body__days" [cells]="dayCells" />
    <div class="body__details">
      <div class="body__details__timezone">
        <mat-icon>language</mat-icon> Time Zone: Central Time
      </div>
      <div class="body__details__duration">
        The appointment is estimated to take {{props.duration}}.
      </div>
      @if ((props.serviceUids.length || 1) > 1) {
      <div class="body__details__note">
        Note: Choosing a fewer number of vehicles may increase available slots.
      </div>
      }
    </div>

    @if (loading) {
      <ysh-dots />
    }
    @if (selectedDay?.slots?.length) {
      <ysh-schedule-cells class="body__time" [cells]="timeCells" columns="4" />
    }
  </div>
</ysh-flow-content>
<ysh-flow-footer class="footer">
  <ysh-button block
    class="footer__btn-next"
    [disabled]="!form.slot || completing"
    [loading]="loading || completing"
    label="Confirm Appointment Time"
    (click)="didTapDone()"
  >
  </ysh-button>
</ysh-flow-footer>
