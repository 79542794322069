@if (_dataSource) {
  @if (_hasHeader) {
    <header class="header">
      <h1 class="header__title">{{_dataSource.title}}</h1>
      <div class="header__tools">
        @if (_hasStartEndDateFilter) {
          <mat-form-field>
            <mat-date-range-input [rangePicker]="picker">
              <input #dateStart matStartDate placeholder="Start Date">
              <input #dateEnd matEndDate placeholder="End Date" (dateChange)="didChangeDate(dateStart.value, dateEnd.value)">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker">
              <mat-icon size-large matDatepickerToggleIcon>date_range</mat-icon>
            </mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        }
        @if (_hasSearchFilter) {
          <mat-form-field>
            <input matInput #input placeholder="Search Filter" (keyup)="didChangeFilter($event)">
            <mat-icon size-large matSuffix>
              @if (filtering) {hourglass_top} @else {search}
            </mat-icon>
          </mat-form-field>
        }
        @for (action of _dataSource.actions; track action) {
          <ysh-button
            [label]="action.buttonLabel"
            [icon]="action.buttonIcon"
            [theme]="action.buttonTheme || 'clear'"
            (click)="action.onTap()"
          />
        }
      </div>
    </header>
  }

  <mat-table matSort [dataSource]="_items" (matSortChange)="didChangeSort($event)">
    <!-- COLUMNS -->
    <ng-container *ngFor="let column of _dataSource.columns" [matColumnDef]="column.field || column.title" >
      <mat-header-cell *matHeaderCellDef mat-sort-header class="{{column.cellSize}}" [disabled]="!column.sortable">{{column.title}}</mat-header-cell>
      <mat-cell *matCellDef="let item" class="{{column.cellSize}}">
        @if (column.component) {
          <ng-container [ngComponentOutlet]="column.component" [ngComponentOutletInputs]="{value: column.field ? item[column.field] : item}"></ng-container>
        } @else if (column.componentWithInputs) {
          <ng-container [ngComponentOutlet]="column.componentWithInputs(item).component" [ngComponentOutletInputs]="column.componentWithInputs(item).inputs"></ng-container>
        } @else {
          <ng-container>{{item[column.field]}}</ng-container>
        }
      </mat-cell>
    </ng-container>

    <!-- NAV COLUMN -->
    @if (_dataSource.displayRowNav) {
      <ng-container *ngIf="onItemTap" matColumnDef="navigation" stickyEnd>
        <mat-header-cell *matHeaderCellDef class="nav-cell"></mat-header-cell>
        <mat-cell *matCellDef="let item" class="nav-cell">
          <button (click)="didTapItem(item)" matTooltip="View Details" matTooltipPosition="left">
            <mat-icon size-medium>open_in_new</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
    }

    <mat-header-row *matHeaderRowDef="_fields"></mat-header-row>
    <mat-row *matRowDef="let row; columns: _fields;"></mat-row>

    <div *matNoDataRow mat-no-data-row>
      @if (_loading) {
        <ysh-dots />
      } @else if (filter?.length) {
        No results for "{{filter}}"
      } @else {
        @if (_dataSource.noDataMessage) {
          {{_dataSource.noDataMessage}}
        } @else {
          No {{_dataSource.title.toLowerCase()}} found...
        }

      }
    </div>
  </mat-table>

  @if (_paginationInfo) {
    <mat-paginator [pageSize]="10" [length]="_paginationInfo?.total_entries" [hidePageSize]="true" (page)="didChangePage($event)" />
  }

}
