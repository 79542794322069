import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { decamelize, decamelizeKeys } from 'humps';
import { BaseEntity, BaseEntityType } from '../models/base';
import moment from 'moment';

interface ApiOptions {
  url: string;
  method?: string;
  params?: any;
  body?: any;
  ops?: boolean;
  includePageInfo?: boolean;
  auth_key?: string;
  headers?: HttpHeaders;
  observe?: any;
}

export interface PageInfo {
  total_entries: number;
  total_pages: number;
  current_page: number;
  previous_page: number;
  next_page: number;
}

export interface GetManyParams {
  page?: number, 
  sortBy?: string,
  sortOrder?: string,
  filter?: string; 
  dateStartInclusive?: string;
  dateEndInclusive?: string;
}

export interface PaginatedResponse<T> {
  pageInfo: PageInfo;
  response: any;
}

export enum FileFormat {
  CSV = 'csv'
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}


  getMany<T extends BaseEntity, U extends GetManyParams>(url: string, params: U, entity?: BaseEntityType<T>): Observable<{ items: T[]; paginationInfo: PageInfo }> {
    params.dateStartInclusive =  params.dateStartInclusive && moment(params.dateStartInclusive).format('YYYY-M-D');
    params.dateEndInclusive =  params.dateEndInclusive && moment(params.dateEndInclusive).format('YYYY-M-D');
    params.sortBy = params.sortBy && decamelize(params.sortBy);
    const req = this.call({
        url,
        method: 'GET',
        includePageInfo: true,
        params,
      })
      .pipe(
        map((res: any) => {
          const items = res.body.map(data => entity ? new entity(data) : data);
          const paginationHeaders = res.headers.get('x-pagination');
          const paginationInfo = paginationHeaders && JSON.parse(paginationHeaders);
          return { items, paginationInfo };
        }),
      );
    req.subscribe(new EmptySubscriber());
    return req;
  }

  get<T extends BaseEntity>(url: string, entity?: BaseEntityType<T>, params?: any): Observable<T> {
    const req = this.call({
      url,
      method: 'GET',
      params
    }).pipe(
      map((res: any) => entity ? new entity(res) : res)
    )
    req.subscribe(new EmptySubscriber());
    return req;
  }

  download(url: string, name: string, format: FileFormat, params?: any): Observable<any> {
    const options: ApiOptions = {
      url, 
      method: 'GET',
      params: { 
        ...params,
        fileFormat: format
      }
    }
    this.sanitizeOptions(options);
    options.observe = options.includePageInfo ? 'response' : 'body';
    const req = this.http.request(options.method, options.url, {...options, responseType: 'blob' }).pipe(shareReplay(1));
    req.subscribe(blob => {
      const url = URL.createObjectURL(blob)
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.${format}`
      a.click();
      URL.revokeObjectURL(url);
    })
    return req;
  }

  post<T extends BaseEntity>(url: string, entity?: BaseEntityType<T>, body?: any): Observable<T> {
    const req = this.call({
      url,
      method: 'POST',
      body
    }).pipe(
      map((res: any) => entity ? new entity(res) : res)
    )
    req.subscribe(new EmptySubscriber());
    return req;
  }

  postBulk<T extends BaseEntity>(url: string, entity?: BaseEntityType<T>, body?: any): Observable<T[]> {
    const req = this.call({
      url,
      method: 'POST',
      body
    }).pipe(
      map((res: any) => res.map(data => entity ? new entity(data) : data))
    )
    req.subscribe(new EmptySubscriber());
    return req;
  }

  call<T>(options: ApiOptions): Observable<T> {
    this.sanitizeOptions(options);
    options.observe = options.includePageInfo ? 'response' : 'body';
    return this.http.request<T>(options.method, options.url, options).pipe(shareReplay(1));
  }

  private sanitizeOptions(options: ApiOptions) {
    options.method = options.method || 'GET';
    options.body = options.body ? decamelizeKeys(options.body, { separator: '_' }) : {};
    options.params && this.removeUndefinedParams(options.params);
    options.params = options.params || {};
    options.params = decamelizeKeys(options.params);
    options.params = this.convertToParams(options.params);
    options.url = options.url.includes("http") ? options.url : environment.apiEndpoint + options.url;
    return options;
  }

  private convertToParams(body: any = {}) {
    let params = new HttpParams();
    for (let key in body) {
      if (body[key] instanceof Array) {
        const list = body[key];
        for (let item of list) {
          params = params.append(`${key}[]`, item);
        }
      } else {
        params = params.set(key, body[key]);
      }
    }
    return params;
  }

  private removeUndefinedParams(params: any) {
    Object.keys(params).forEach(key => params[key] === undefined && delete params[key])
  }
}

@Injectable()
export class EmptySubscriber {
  next = () => {};
  error = () => {};
  complete = () => {};
}
