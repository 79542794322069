<ysh-flow-nav-bar><ysh-back-button type="close" (click)="didTapDismiss()"></ysh-back-button></ysh-flow-nav-bar>
<ysh-flow-content>
  <form class="form" [formGroup]="formGroup" (ngSubmit)="handleSubmit()">
    <h2 class="form__heading">{{formHeading}}</h2>

    @if (formError) { 
      <p class="form__error">{{formError}}</p>
    }
    
    <!-- Stripe Fields -->
    <div class="form__field-stripe" id="card-number-element"></div>
    <div class="form__field-stripe half" id="card-expiry-element"></div>
    <div class="form__field-stripe half" id="card-cvc-element"></div>

    <!-- Zip Code Field -->
    <mat-form-field class="form__field">
      <mat-label>Zip Code</mat-label>
      <input [(ngModel)]="postalCode" matInput formControlName="zipCode">
    </mat-form-field>

    <ysh-button
      class="form__submit"
      [label]="formSubmitLabel"
      [loading]="formSubmitting"
      [disabled]="formSubmitting"
      block
    ></ysh-button>

    <div class="form__fineprint">{{formFineprint}}</div>
  </form>
</ysh-flow-content>