import { Component, Input, OnInit } from '@angular/core';
import { FlowPage } from '../../flow-director';
import { createDays, TimeSlot } from '../../../models/time-slot';
import { Service } from '../../../models/service';
import { ModalService } from '../../../services/ui/modal/modal.service';
import { TimeSlotService } from '../../../services/api/time-slot/time-slot.service';
import { Day } from '../../../models/day';
import { finalize, Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { YshButtonComponent } from '../../../components/ysh-button/ysh-button.component';
import { YshFlowNavBarComponent } from '../../../components/ysh-flow-nav-bar/ysh-flow-nav-bar.component';
import { YshBackButtonComponent } from '../../../components/ysh-back-button/ysh-back-button.component';
import { YshFlowContentComponent } from "../../../components/ysh-flow-content/ysh-flow-content.component";
import { YshFlowFooterComponent } from "../../../components/ysh-flow-footer/ysh-flow-footer.component";
import { YshDotsComponent } from "../../../components/ysh-dots/ysh-dots.component";
import { YshScheduleCell, YshScheduleCellsComponent } from "../../../components/ysh-schedule-cells/ysh-schedule-cells.component";
import { AnalyticsService } from '../../../services/analytics/analytics.service';


export interface TimeSelectorPageProps {
  service?: Service;
  duration?: string;
  timeSlots?: TimeSlot[];
  serviceUids?: string[];
}

export interface TimeSelectorPageForm {
  slot?: TimeSlot;
}

@Component({
  selector: 'ysh-time-selector',
  standalone: true,
  templateUrl: './time-selector.page.html',
  styleUrls: ['./time-selector.page.scss'],
  imports: [MatIconModule, YshButtonComponent, YshFlowNavBarComponent, YshBackButtonComponent, YshFlowContentComponent, YshFlowFooterComponent, YshBackButtonComponent, YshDotsComponent, YshScheduleCellsComponent]
})
export class TimeSelectorPage implements OnInit, FlowPage {
  @Input() onComplete: (slot: TimeSlot) => Observable<boolean>;;
  @Input() onDismiss: () => void;
  @Input() preventBackNavigation?: boolean = false;
  @Input() props: TimeSelectorPageProps;

  loading = false;
  completing = false;
  form: TimeSelectorPageForm = {};
  days: Day[] = [];
  selectedDay?: Day;

  constructor(
    private analytics: AnalyticsService,
    private modalService: ModalService,
    private timeSlotService: TimeSlotService) {}
  

  get dayCells() {
    return this.days.map((day): YshScheduleCell => ({
      value: day.displayName,
      subvalue: day.nextDateString(false,false),
      disabled: !this.isDayServiced(day) || this.completing,
      selected: this.isDaySelected(day),
      onSelect: () => this.didTapOnDay(day),
    }));
  }

  get timeCells() {
    return this.selectedDay?.slots.map((slot): YshScheduleCell => ({
      value: slot.startTimeString,
      disabled: !slot.available || this.completing,
      selected: slot.startTimeString == this.form.slot?.startTimeString,
      onSelect: () => this.didTapOnSlot(slot),
    }));
  }

  ngOnInit() {
    this.analytics.trackView('TimeSelectorPage');
  
    if (this.props.timeSlots?.length) {
      this.processSlots(this.props.timeSlots);
    } else {
      this.loadTimeSlots();
    }
  }

  // Actions

  didTapOnDay(day) {
    this.selectedDay = day;
    this.setDefaultTime();
  }

  didTapOnSlot(slot: TimeSlot) {
    this.form.slot = slot;
  }

  didTapDismiss() {
    this.modalService.dismiss();
  }

  didTapBack() {
    this.onDismiss?.();
  }

  didTapDone() {
    this.completing = true;
    this.onComplete(this.form.slot!).pipe(
      finalize(()=>{ 
        this.completing = false 
      })
    ).subscribe(complete => {
        console.log("Complete");
    });
  }

  // Data

  private loadTimeSlots() {
    this.loading = true;
    return this.timeSlotService
      .getMany({
        from: null,
        to: null,
        serviceUids: this.props.serviceUids
      }).pipe(finalize(()=>{
        this.loading = false;
      }))
      .subscribe((slots) => {
        this.processSlots(slots.items);
      });
  }

  private processSlots(slots: TimeSlot[]) {
    this.days = createDays(slots);
    this.setDefaultDay();
    this.setDefaultTime();
  }

  private setDefaultDay() {
    this.selectedDay = this.days.find((day) => day.slots.find((slot) => slot.available));
  }

  private setDefaultTime() {
    this.form.slot = this.selectedDay?.slots.find((slot) => slot.available);
  }

  // Helpers

  isDaySelected(someDay: Day) {
    return this.selectedDay?.dayIndex === someDay.dayIndex;
  }

  isDayServiced(day: Day) {
    return day.slots && day.slots.length > 0;
  }
}
