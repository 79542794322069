import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { YshDialogProps } from '../../../components/ysh-dialog/ysh-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogProps: YshDialogProps | null;
  private dialogVisibility = new BehaviorSubject<boolean>(false);

  dialogVisibility$ = this.dialogVisibility.asObservable();

  getDialogProps(): YshDialogProps {
    return this.dialogProps;
  }

  showDialog(props: YshDialogProps) {
    this.dialogProps = props;
    this.dialogVisibility.next(true);
  }

  hideDialog() {
    this.dialogVisibility.next(false);
  }
}
