
import { IonNav } from '@ionic/angular/standalone';
import { BackButtonService } from '../shared/back-button.service';

export type ComponentType<T> = new (...args: any[]) => T;

export interface FlowPage {
  onComplete: (...result: any) => void;
  onDismiss?: () => void;
  preventBackNavigation?: boolean;
}

export type FlowPageParams<T extends FlowPage> = {
  preventBackNavigation?: boolean;
} & (T extends { onComplete } ? { onComplete: T['onComplete'] } : {}) &
  (T extends { onDismiss } ? { onDismiss?: T['onDismiss'] } : {});

export interface Flow {
  nav: IonNav;
  nextPage(): Nullable<FlowPageOptions<FlowPage>>;
  flowDidComplete(): void;
  preventAnimation?: boolean;
  backButtonService?: BackButtonService;
}

type WithProps = { props: any };

export type FlowPageOptions<T extends FlowPage> = {
  page: ComponentType<T>;
  /** @deprecated use props instead for type safety */
  pageProps?: Partial<T>;
  urlHash?: string;
} & FlowPageParams<T> &
  (T extends WithProps ? { props: T['props'] } : {});

export class FlowDirector {
  constructor(private flow: Flow, options?: FlowPageOptions<FlowPage> | null) {
    const startOptions = options || this.flow.nextPage();
    if (startOptions) {
      this.setRoot(startOptions);
    }
  }

  next(setAsRoot = false): boolean {
    const options = this.flow.nextPage();
    let complete = false; 
    if (options) {
      if (!options.preventBackNavigation) {
        let uid;
        if (this.flow.backButtonService) {
          uid = this.flow.backButtonService.add(() => {
            dismissCallback();
            this.back();
          }, options.urlHash);
        }

        const dismissCallback = options['onDismiss'];
        options['onDismiss'] = () => {
          dismissCallback?.();
          if (this.flow.backButtonService) {
            this.flow.backButtonService.remove(uid);
          }
          this.back();
        }
      } else {
        if (this.flow.backButtonService) {
          this.flow.backButtonService.add(() => {
          }, options.urlHash);
        }
      }

      if (setAsRoot) {
        this.setRoot(options);
      } else {
        this.flow.nav.push(options.page, this.generateProps(options), {
          animated: !this.flow.preventAnimation,
        });
      }
    } else {
      complete = true;
      this.flow.flowDidComplete?.();
    }
    return complete;
  }

  setRoot(options: FlowPageOptions<FlowPage>) {
    this.flow.nav.setRoot(options.page, this.generateProps(options));
  }

  private generateProps<T extends FlowPage>(options: FlowPageOptions<T>): Partial<FlowPage> {
    const props = {
      ...options.pageProps,
      onComplete: options.onComplete,
      preventBackNavigation: options.preventBackNavigation,
    };
    props['onDismiss'] = options['onDismiss'];
    props['props'] = options['props'];
    return props;
  }

  private async back() {
    if (await this.flow.nav.canGoBack()) {
      await this.flow.nav.pop({ animated: !this.flow.preventAnimation });
    }
  }
}
