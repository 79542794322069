import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { YshDotsComponent } from "../ysh-dots/ysh-dots.component";
import { YshBadgeComponent } from "../ysh-badge/ysh-badge.component";

export interface AttributesListItem {
  label: string,
  value: string | number | Date,
  valueIsBadge?: boolean;
}

@Component({
  selector: 'ysh-attributes',
  templateUrl: './ysh-attributes.component.html',
  styleUrl: './ysh-attributes.component.scss',
  standalone: true,
  imports: [MatIconModule, YshDotsComponent, YshBadgeComponent],
})
export class YshAttributesComponent {
  @Input() listItems: AttributesListItem[] = [];
  @Input() imageSrc?: string;
}
