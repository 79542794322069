import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Toast, ToastService, ToastType } from '../../services/ui/toast/toast.service';

@Component({
  selector: 'ysh-toast',
  templateUrl: './ysh-toast.component.html',
  styleUrl: './ysh-toast.component.scss',
  standalone: true,
  imports: [MatIconModule],
})
export class YshToastComponent implements OnInit {
  toast: Toast | null = null;

  private icons: Record<ToastType, string> = {
    // Material Icon Names
    info: 'info',
    success: 'check_circle',
    warning: 'warning_amber',
    error: 'error_outline',
  };

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.toast$.subscribe(toast => this.toast = toast);
  }

  getIcon(type: string): string {
    return this.icons[type] || 'info';
  }
}
