"use strict";
/**
 * random.js
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomBytes = exports.getRandomSampledString = exports.getRandomAsciiString = exports.getRandomString = void 0;
var util = __importStar(require("js-crypto-env"));
/**
 * Secure random string generator based on getRandomBytes,
 * which is composed of uppercase or lowercase alphanumeric characters;
 * @param {Number} len - Length of string.
 * @return {String} - Generated random ASCII string.
 */
var getRandomString = function (len) {
    var array = (0, exports.getRandomBytes)(len);
    var types = (0, exports.getRandomBytes)(len); // indicating alphanumeric, upper, lower
    var finalString = '';
    // Ascii code
    // 1-0: 0x30 -- 0x39, 10 chars
    // A-Z: 0x41 -- 0x5a,
    // a-z: 0x61 -- 0x7a
    for (var i = 0; i < len; i++) {
        types[i] = (types[i] % 3);
        array[i] = (types[i] === 0) ?
            (array[i] % 10) + 0x30 :
            (array[i] % 26) + ((types[i] === 1) ? 0x41 : 0x61);
        finalString += String.fromCharCode(array[i]);
    }
    return finalString;
};
exports.getRandomString = getRandomString;
/**
 * Secure random 'ASCII' string generator based on getRandomBytes;
 * @param {Number} len - Length of ASCII string.
 * @return {String} - Generated random ASCII string.
 */
var getRandomAsciiString = function (len) {
    var array = (0, exports.getRandomBytes)(len);
    var finalString = '';
    // Ascii code excluding control characters are in 0x20 -- 0x7e
    for (var i = 0; i < len; i++) {
        array[i] = (array[i] % 0x5e) + 0x20;
        finalString += String.fromCharCode(array[i]);
    }
    return finalString;
};
exports.getRandomAsciiString = getRandomAsciiString;
/**
 * Secure random string generator based on getRandomBytes,
 * which is composed of givin character candidates;
 * @param {Number} len - Length of string.
 * @param {String} candidates - Candidates string to sample randomly.
 * @return {String} - Generated random string.
 */
var getRandomSampledString = function (len, candidates) {
    var candidateLen = candidates.length;
    if (candidateLen === 0)
        return '';
    var array = (0, exports.getRandomBytes)(len);
    var finalString = '';
    for (var i = 0; i < len; i++) {
        finalString += candidates[array[i] % candidateLen];
    }
    return finalString;
};
exports.getRandomSampledString = getRandomSampledString;
/**
 * Secure random generator that returns a byte array filled with cryptographically secure random bytes
 * @param {Number} len - Byte length of random sequence.
 * @return {Uint8Array} - Generated random sequence.
 * @throws {Error} - Throws if UnsupportedEnvironment.
 */
var getRandomBytes = function (len) {
    var webCrypto = util.getRootWebCrypto(); // web crypto api
    var nodeCrypto = util.getNodeCrypto(); // implementation on node.js
    if (typeof webCrypto !== 'undefined' && typeof webCrypto.getRandomValues === 'function') {
        var array = new Uint8Array(len);
        webCrypto.getRandomValues(array); // for modern browsers
        return array;
    }
    else if (typeof nodeCrypto !== 'undefined') { // for node
        return new Uint8Array(nodeCrypto.randomBytes(len));
    }
    else {
        throw new Error('UnsupportedEnvironment');
    }
};
exports.getRandomBytes = getRandomBytes;
