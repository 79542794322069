import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, throwError } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, tap, catchError } from 'rxjs/operators';
import { User } from '../../models/user';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedIn$ = this.auth.authData$.pipe(
    map(data => (data ? true : false)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  private currentUserSource$ = new BehaviorSubject<User>(null);
  currentUser$ = this.currentUserSource$.pipe(shareReplay(1));

  private initParams: URLSearchParams;

  constructor(private router: Router, private api: ApiService, private auth: AuthService) {
    this.loggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        this.getUser().subscribe();
      }
    });
    this.initParams = new URL(window.location.toString()).searchParams;
  }

  authRequest(endpoint: string, params: Record<string, any>) {
    return this.api
      .call<HttpResponse<any>>({
        url: endpoint,
        method: 'POST',
        params: { ...params, 'authentication-method': 'devise-token-auth' },
        includePageInfo: true,
      })
      .pipe(
        map(res => {
          this.auth.save(res.headers);
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.logout();
          }
          return throwError(() => err);
        }),
      );
  }

  signup(firstName: string, lastName: string, phone: string, email: string, password: string) {
    const SIGNUP_KEY = 'signup_ref';
    const signupRef =
      this.initParams.get(SIGNUP_KEY) || window.location.href;
    return this.authRequest('/users', { firstName, lastName, phone, email, password, signupRef });
  }
  
  login(email: string, password: string) {
    return this.authRequest('/auth/sign_in', { email, password });
  }

  logout() {
    this.auth.logout();
  }

  getUser() {
    return this.api
      .call({
        url: `/user`,
        method: 'GET',
      })
      .pipe(
        map(result => new User(result as any)),
        tap(user => this.currentUserSource$.next(user)),
      );
  }
}
