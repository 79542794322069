<div class="attributes">
  @if (listItems) {
    @if (imageSrc) {
      <div class="attributes__image">
        <img src={{imageSrc}}>
      </div>
    }
    <div class="attributes__list">
      @for (item of listItems; track item) {
        <div class="attributes__list__item">
          <div class="attributes__list__item__label">{{item.label}}</div>
          <div class="attributes__list__item__value">
            @if (item.valueIsBadge) {
              <ysh-badge value="{{item.value || 'Unknown'}}" />
            } @else {
              {{item.value || 'Unknown'}}
            }
          </div>
        </div>
      }
    </div>
  }
  @else {
    <ysh-dots />
  }
</div>
