<div class="grid" 
  style.grid-template-columns="repeat({{numberOfColumns}}, minmax(0, 1fr))"
  style.grid-template-rows="repeat({{numberOfRows}}, minmax(0, 1fr))">
  @for (tile of tiles; track tile) {
    <div class="grid__tile" [ngStyle]="{'grid-area':tile.gridArea, 'aspect-ratio':tile.aspectRatio}">
      <div class="grid__tile__heading">{{tile.heading}}</div>
      <div class="grid__tile__subheading">{{tile.subheading}}</div>
      @if (tile.infotip) {
        <div class="grid__tile__infotip" matTooltip={{tile.infotip}} matTooltipPosition="left">
          <mat-icon size-medium>info</mat-icon>
        </div>
      }
      <div class="grid__tile__component">
        <ng-container [ngComponentOutlet]="tile.component" [ngComponentOutletInputs]="tile.componentInput" />
      </div>
    </div>
  }
</div>
