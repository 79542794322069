import { Component, input, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Stripe, StripeElements, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement } from '@stripe/stripe-js';
import { StripeService } from '../../services/stripe/stripe.service';
import { YshButtonComponent } from "../../components/ysh-button/ysh-button.component";
import { YshFlowNavBarComponent } from "../../components/ysh-flow-nav-bar/ysh-flow-nav-bar.component";
import { YshBackButtonComponent } from "../../components/ysh-back-button/ysh-back-button.component";
import { YshFlowContentComponent } from "../../components/ysh-flow-content/ysh-flow-content.component";
import { FlowPage } from '../flow-director';
import { CreditCardService } from '../../services/api/credit-card/credit-card.service';
import { finalize, flatMap, from, mergeMap, Observable, timeout, timer } from 'rxjs';
import { ModalService } from '../../services/ui/modal/modal.service';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'ysh-add-card',
  templateUrl: './add-card.page.html',
  styleUrl: './add-card.page.scss',
  standalone: true,
  imports: [ReactiveFormsModule, MatFormFieldModule, MatInputModule, YshButtonComponent, YshFlowNavBarComponent, YshBackButtonComponent, YshFlowContentComponent],
})
export class AddCardPage implements OnInit, FlowPage {
  @Input() formHeading = 'Add Credit Card';
  @Input() formSubmitLabel = 'Save Card';
  @Input() formErrorMessage = 'An error occurred. Please try again.';
  @Input() formFineprint = 'Your credit card is handled securely by Stripe®. Your card will not be charged until the order is complete.';
  @Input() onComplete: (boolean) => void;
  @Input() onDismiss?: () => void;

  formGroup: FormGroup;
  stripe: Stripe;
  elements: StripeElements;
  cardNumberElement: StripeCardNumberElement;
  cardExpiryElement: StripeCardExpiryElement;
  cardCvcElement: StripeCardCvcElement;
  postalCode: string;
  formError: string | undefined;
  formSubmitting = false;

  constructor(
    private analytics: AnalyticsService,
    private creditCardService: CreditCardService,
    private formBuilder: FormBuilder, 
    private modalService: ModalService, 
    private stripeService: StripeService) {}

  ngOnInit(): void {
    this.analytics.trackView('AddCardPage');
    this.initNormalFields();
    this.initStripeFields();
  }

  initNormalFields(): void {
    this.formGroup = this.formBuilder.group({
      zipCode: ['', [Validators.required, Validators.pattern(/^\d{5}$/)]],
    });
  }

  async initStripeFields(): Promise<void> {

    const font = {
      family: 'Geologica', // Yoshi Sans Fallback
      src: 'url(https://fonts.gstatic.com/s/geologica/v1/oY1l8evIr7j9P3TN9YwNAdyjzUyDKkKdAGOJh1UlCDUIhAIdhCZOn1fLsig7jfvCCPHZckUWE1lELWNN-w.woff2)'
    };

    const style = {
      base: {
        fontFamily: 'Geologica, sans-serif',
        fontWeight: '200',
        fontSize: '16px',
        color: 'rgb(45,65,95)',
        '::placeholder': { color: 'inherit' },
        ':focus::placeholder': { color: 'rgb(180,190,205)' },
      },
      invalid: { color: 'rgb(255,110,95)' },
    };

    const classes = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    };

    try {
      // Init Stripe
      this.stripe = await this.stripeService.instance;
      this.elements = this.stripe.elements({ fonts: [font] });
  
      // Create Field Elements
      this.cardNumberElement = this.elements.create('cardNumber', { style, classes });
      this.cardExpiryElement = this.elements.create('cardExpiry', { style, classes });
      this.cardCvcElement = this.elements.create('cardCvc', { style, classes });
  
      // Mount Field Elements
      this.cardNumberElement.mount('#card-number-element');
      this.cardExpiryElement.mount('#card-expiry-element');
      this.cardCvcElement.mount('#card-cvc-element');

    } catch (error) {
      console.error(error);
    }
  }
  
  async handleSubmit(): Promise<void> {
    if (this.formSubmitting) return;
    this.formSubmitting = true;
    const stripeRequest = this.stripeService.instance!.createToken(this.cardNumberElement, {
      address_zip: this.postalCode
    });
    from(stripeRequest).pipe(
      mergeMap((token) => {
        return this.creditCardService.create(token!.token.id)
      }),
      finalize(() => {
        this.formSubmitting = false;
      }),
    ).subscribe({
      complete: () => {
        this.onComplete(true);
        this.modalService.dismiss();
      },
      error: (error) => {
        this.onComplete(false);
        this.formError = error.error?.errors?.[0]?.message || "Error saving card";
      }
    });
  }

  // Actions 

  didTapDismiss(){
    if (this.onDismiss) {
      this.onDismiss();
    } else {
      this.modalService.dismiss(false);
    }
  }
}
