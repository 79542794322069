<header class="page-header">
  <h1 class="page-header__title">{{title}}</h1>
  @if (backLinkRoute) {
    <a class="page-header__back" routerLink={{backLinkRoute}}>
      <mat-icon size-medium>arrow_back</mat-icon> {{backLinkLabel}}
    </a>
  } @else if (subtitle) {
    <p class="page-header__subtitle">{{subtitle}}</p>
  }
  <div class="page-header__accessory">
    <ng-content></ng-content>
  </div>
</header>
