import { Component, Input } from '@angular/core';
import { YshPanelComponent } from '../../components/ysh-panel/ysh-panel.component';
import { YshPageHeaderComponent } from '../../components/ysh-page-header/ysh-page-header.component';
import { Trait, YshTraitsComponent } from '../../components/ysh-traits/ysh-traits.component';
import { GalleryImage, YshGalleryComponent } from '../../components/ysh-gallery/ysh-gallery.component';
import { Order } from '../../models/order';
import { OrderService } from '../../services/api/order/order.services';
import { AppRoutes } from '../../app.routes';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrl: './inspection.component.scss',
  standalone: true,
  imports: [
    YshPageHeaderComponent,
    YshPanelComponent,
    YshTraitsComponent,
    YshGalleryComponent,
  ],
})
export class InspectionComponent {
  @Input() uid: string;

  order: Order;
  orderOverview: Trait[];
  orderDetails: Trait[];
  orderPhotos: GalleryImage[] = [];
  orderVehicleRoute: string;

  constructor(private orderService: OrderService) {}

  ngOnInit(): void {
    this.getOrder();
  }
  
  getOrder() {
    this.orderService.get(this.uid).subscribe(order => {
      this.order = order;
      this.orderOverview = [
        { 
          label: 'Description', 
          value: this.order?.service?.name || 'Unknown',
        },
        {
          label: 'Vehicle', 
          value: this.order?.vehicle?.description || 'Unknown',
          linkHref: `/${AppRoutes.Vehicles}/${this.order.vehicle.uid}` 
        },
        { 
          label: 'VIN', 
          value: this.order?.vehicle?.vin || 'Unknown',
        },
        { 
          label: 'Completed', 
          value: this.order?.timeCompletedString || 'Unknown',
        },
        { 
          label: 'Inspector', 
          value: this.order?.inspection?.inspectorName || 'Unknown',
        },
        { 
          label: 'Inspection Result', 
          value: this.order?.inspection?.result || 'None', 
          badgeTheme: 'status',
        },
        { 
          label: 'Inspection Form', 
          value: this.order?.inspection?.documentUrl ? 'Download PDF' : 'None', 
          linkHref: this.order?.inspection?.documentUrl,
          linkIcon: 'description',
        },
      ];      

      this.orderDetails = this.order?.inspection?.details?.map(detail => ({
        label: detail.question,
        value: detail.answer || 'None',
        badgeTheme: 'status',
      }));
      
      this.orderPhotos = this.order?.inspection?.photos?.map(photo => ({
        src: photo.url,
        // TODO: Fix getters not working for this
        caption: photo.label.replace(/_/g,' ') || 'Inspection Photo',
      }));
    });
  }
}
