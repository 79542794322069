<div class="service-card" [class.selectable]="selectable" [class.selected]="selected">
  <div class="service-card__content">
    <div class="service-card__content__title">{{title}}</div>
    <div class="service-card__content__details">
      @if (detailsIcon) {
        <mat-icon>{{detailsIcon}}</mat-icon>
      }
      {{details}}
    </div>
    @if (description) {
      <div class="service-card__content__description">
        {{description}}
      </div>
    }
  </div>
  @if (deletable) {
    <button class="service-card__delete" (click)="handleDelete()">
      <mat-icon>clear</mat-icon>
    </button>
  }
</div>
