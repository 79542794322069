<ysh-flow-nav-bar><ysh-back-button type="close" (click)="didTapDismiss()"></ysh-back-button></ysh-flow-nav-bar>
<ysh-flow-content>
  <div class="intro">
    <h2 class="intro__heading">Choose a service option:</h2>
  </div>
  <div class="body">
    @for (service of props.services; track $index) {
      <ysh-service-card 
        title="{{service.title}} - ${{service.price}}"
        details="{{service.stopDuration}} Minutes"
        detailsIcon="schedule"
        description="{{service.shortDescription}}"
        [selectable]="true"
        [selected]="$index === selectedIndex"
        (click)="didTapCard($index)"
      />
    }
  </div>
</ysh-flow-content>
